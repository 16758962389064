<template>
  <div class="mt-5">
    <v-toolbar flat>
            <v-toolbar-title style="font-weight:600">ការកំណត់ Payout</v-toolbar-title>
      <v-spacer></v-spacer>
       <v-select
            v-model="type_id"
            :items="items"
            item-text="name"
            item-value="_id"
            :label="$t('input.chose_type')"
            @input="selectCurrencyType(type_id)"
          ></v-select>
                <v-spacer></v-spacer>
       <v-select
            v-model="channel_type"
            :items="channel_items"
            item-text="name"
            item-value="_id"
            label="Chose Channel"
            @input="selectChannelType(channel_type)"
          ></v-select>

    </v-toolbar>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["darkmode", "status", "params", "dataDelete"],
  data() {
    return {

         items:[
        { _id:1,
          name:"លុយដុល្លា - Usd"
        },
        { _id:2,
          name:"លុយរៀល - Khmer"
        },
        { _id:3,
          name:"លុយបាត - Baht"
        },
        ],
        channel_items:[
        { _id:1,
          name:"Channel - 1"
        },
        { _id:2,
          name:"Channel - 2"
        },
        { _id:3,
          name:"Channel - 3"
        },
        { _id:4,
          name:"Channel - 4"
        },
        ],
        type_id:1,
        channel_type:1
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
     selectCurrencyType(type_id) {
        this.params.currency_type = type_id        
        this.fetchGetAllPayOut(this.params)
    },
    selectChannelType(channel_type) {
        this.params.channel_id = channel_type        
        this.fetchGetAllPayOut(this.params)
    },
    async deleteItemConfirm() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: this.dataDelete}
      });
      this.fetchGetAllPayOut(this.params)
      this.dialogDelete = false;
    },

    async onCreateDialog() {
      this.fetchPopUp(true)
    },
    ...mapActions("$_weight", ["fetchGetAllPayOut", "deleteData", "fetchPopUp"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
